import { render, staticRenderFns } from "./menuGuncelle.vue?vue&type=template&id=4d370abb&scoped=true&"
import script from "./menuGuncelle.vue?vue&type=script&lang=js&"
export * from "./menuGuncelle.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d370abb",
  null
  
)

export default component.exports